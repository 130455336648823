import React from "react";
import theme from "theme";
import { Theme, Link, Image, Em, Strong, Text, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms"} />
		<Helmet>
			<title>
				Dropify Terms of Use & Privacy Policy
			</title>
			<meta name={"description"} content={"Dropify Terms of Use & Privacy Policy"} />
			<meta property={"og:title"} content={"Dropify Terms of Use & Privacy Policy"} />
			<meta property={"og:description"} content={"Dropify Terms of Use & Privacy Policy"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/cover.png?v=2021-12-12T04:10:19.294Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack md-justify-content="space-between">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/terms"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="600px"
			display="flex"
			flex-direction="column"
			sm-min-height="auto"
		>
			<Section
				padding="60px 0"
				sm-padding="40px 0"
				min-height="600px"
				background="--color-light"
				display="flex"
				flex-direction="column"
				sm-min-height="auto"
			>
				<Text
					font="--base"
					text-transform="uppercase"
					letter-spacing="1px"
					max-width="850px"
					color="--grey"
					margin="0px"
				/>
				<Text
					as="h1"
					margin="8px 0px 32px"
					font="--headline1"
					md-font="--headline2"
					color="--dark"
					max-width="850px"
				>
					Terms of Use
				</Text>
				<Text
					margin="0px"
					width="100% border-box"
					font="--lead"
					color="--grey"
					md-width="100%"
					sm-margin="0px 0px 32px 0px"
				>
					DROPIFY TECHNOLOGIES INC. WEBSITE TERMS OF USE
					<br />
					LAST UPDATED: 2022-03-03
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						1. OVERVIEW
					</Strong>
					<br />
					These Terms of Use form a legally binding contract between DROPIFY TECHNOLOGIES
INC. ("we" or "us") and the people and companies (“Users” or “you”) that access and use
our website located at dropify.net or any related website or mobile platform controlled by
us (collectively, the "Website"). These Terms of Use apply to all services provided through
our Website and any other software application we operate, and all content, services, and
products available at or through the Website.
It is very important that you read and fully understand these Terms of Use before using
the Website. By agreeing to these Terms of Use and using the Website, you are entering
into a legally binding contract which will impact your rights.
By accessing any part of the Website, you agree to be bound by these Terms of Use. You
are also confirming that you have read and fully understood these Terms of Use. If you do
not agree to or understand all of these Terms of Use, then you may not access the Website
or use any services available on the Website.
These Terms of Use should be read in conjunction with the Privacy Policy and any other
rules, guidelines, or policies posted on the Website.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						2. SERVICES AVAILABLE ON THE WEBSITE
					</Strong>
					<br />
					Without restriction, we generally offer the following services through the Website:
We provide services to web creators to help them monetize their business.
The services we offer are subject to change over time. By using the Website, you are
confirming that you have determined that the services are appropriate for your needs. We
do not guarantee that these services meet your needs or that they are suitable for your
specific purposes.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						3. OWNERSHIP OF YOUR FILES
					</Strong>
					<br />
					When you choose to upload documents or data (including any images or text), DROPIFY
TECHNOLOGIES INC. does not review or pre-screen the contents of electronic data
uploaded or posted to the Website (“Content”) and DROPIFY TECHNOLOGIES INC. claims
no intellectual property rights with respect to the Content.
However, by posting, uploading, inputting or submitting any Content whatsoever to the
Website, you are granting DROPIFY TECHNOLOGIES INC. an irrevocable, royalty free
licence while the Content remains uploaded to the Website, to use the Content for any
purpose related to the use and promotion of its business and the Website, including the
right to copy, distribute, edit, and publicly display such content with or without
attribution.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						4. PAYMENT PROCEDURES
					</Strong>
					<br />
					Payments for services or products available on the Website will be charged to you in
accordance with the policies, procedures, and timelines posted on the relevant sections of
the Website.
You agree to pay the fees applicable to your subscription and any other applicable fees,
including but not limited to fees relating to the processing of transactions under your
account (“Fees”).
All initial and recurring Fees will be charged to the credit card that you authorize for your
account. It is your responsibility to keep your authorized credit card valid and up to date
at all times. We may terminate or block access to your account if your credit card becomes
expired or otherwise invalid at the time any Fees become due.
For purposes of processing payments, we use the following service provider:
STRIPE. Before using the Website, you must first review and approve the terms and conditions
governing the use of these third-party payment processors, which are available at the
following website(s): https://www.stripe.com
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						5. YOUR ACCOUNT
					</Strong>
					<br />
					In order to fully use the Website, you will be required to create an account. Information submitted by you in order to create an account will be subject to our Privacy Policy, which
is available for review on the Website, and which you are required to approve before
creating an account. The Privacy Policy also dictates how to terminate your account and
how you can have your personal identifying information deleted from our servers.
The terms governing the features and capabilities of your account and the related fees can
be found on the Website.
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						<br />
						6. TERMINATION
					</Strong>
					<br />
					You may cancel and terminate your Account at any time in accordance with the terms and
policies posted on the Website.
If at the date of termination of your Account, there are any outstanding payments owing
by you to us, you will receive one final invoice via email. Once that invoice has been paid
in full, you will not be charged again.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						7. LIMITATION OF LIABILITY
					</Strong>
					<br />
					Except in a case where we are in violation of these Terms of Use, we will not be held liable
for, and you hereby fully waive the right to claim for, any loss, injury, claim, liability or
damages of any kind resulting in any way from use of the Website.
Your use of the Website is at your sole risk. The Website is provided on an “as is” and “as
available” basis without any warranty or condition, express, implied or statutory. We do
not warrant that your use of the Website will be uninterrupted, secure or error-free.
In no event will we have any liability to you or any third party for any lost profits or
revenues or for any indirect, special, incidental, consequential, or punitive damages
however caused, whether in contract, tort, or otherwise, and whether or not you or the
third party have been advised of the possibility of such damages. In the event the
foregoing paragraph, or any part thereof, is void under applicable law, this paragraph, or
such part thereof, shall be inapplicable.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						8. INDEMNIFICATION
					</Strong>
					<br />
					You agree to indemnify and hold harmless DROPIFY TECHNOLOGIES INC., including our officers, directors, shareholders, employees and agents, from and against any and all
claims and expenses, including legal fees and disbursements, which are made against us
and arise out of your use of the Website, including but not limited to your violation of any
term of these Terms of Use or any other policy posted on the Website.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						9. SECURITY BREACHES
					</Strong>
					<br />
					In order to protect your security, it is your sole responsibility to ensure that all usernames
and passwords used to access the Website are kept secure and confidential.
You must immediately notify us of any unauthorized use of your account, including the
unauthorized use of your password, or any other breach of security.
We will investigate any breach of security on the Website that we determine in our sole
discretion to be serious in nature, but we will not be held responsible or liable in any
manner for breaches of security or any unauthorized access to your account however
arising.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						10. WARRANTIES AND REPRESENTATIONS
					</Strong>
					<br />
					We hereby disclaim all warranties of any kind, whether express, implied, or statutory,
including but not limited to implied warranties as to merchantability or fitness for a
particular purpose as they relate to the Website.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						11. COMPLIANCE WITH LAWS
					</Strong>
					<br />
					You represent and warrant that:
					<br />
					i. You have the authority to bind yourself to these Terms of Use;
					<br />
					ii. Your use of the Website will be solely for purposes that are permitted by these Terms
of Use;
					<br />
					iii. Your use of the Website will not infringe or misappropriate the confidentiality or
intellectual property rights of any User or third party; and
					<br />
					iv. Your use of the Website will comply with all local, provincial and federal laws, rules
and regulations, and with all policies posted on the Website.
					<br />
					You must only use the Website for your own lawful purposes, in accordance with these
Terms of Use and any notice, policy or condition posted on the Website. You may use the
Website on behalf of others or in order to provide services to others but if you do so you
must ensure that you are authorized to do so and that all persons for whom or to whom
services are provided comply with and accept all these Terms of Use.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						12. AGE RESTRICTIONS
					</Strong>
					<br />
					Users Must be Over the Age of 18. You represent and confirm that you are over the age
of 18. We do not target, market, or promote the Website to those under 18. We do not
permit any User under the age of 18 to use the Website.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						13. GOVERNING LAW AND DISPUTE RESOLUTION
					</Strong>
					<br />
					These Terms of Use and any access to or use of the Website shall be governed by, and
construed in accordance with, the laws in force in the Province of Ontario.
If any claim, dispute or controversy occurs between DROPIFY TECHNOLOGIES INC. and a
User relating to the interpretation or implementation of any of the provisions of these
Terms of Use, such dispute shall be resolved by private, confidential and binding
arbitration. Such arbitration shall be conducted by a single arbitrator. The arbitrator shall
be appointed by agreement of the parties or, in the absence of an agreement, such
arbitrator shall be appointed by a judge upon the application of either the User or
DROPIFY TECHNOLOGIES INC. Arbitration shall be held in the Province of Ontario, unless
otherwise agreed by the parties. The arbitration procedure to be followed shall be agreed
by the parties or, in absence of an agreement, determined by the arbitrator. The arbitration
shall proceed in accordance with the provisions of the Arbitration Act, 1991, SO 1991, c
17. Subject to any right of appeal, the decision arrived at by the arbitrator shall be final
and binding. Judgment upon the award rendered by the arbitrator may be entered in any
court having jurisdiction.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						14. WAIVER OF CLASS ACTION
					</Strong>
					<br />
					By using the Website, you agree to resolve any claim or dispute arising between you and
us on an individual basis, rather than addressing such claim or dispute as part of a group
or class. You hereby waive any right you may have to commence or participate in any class action lawsuit commenced against DROPIFY TECHNOLOGIES INC. or its affiliates related
to any claim, dispute or controversy arising from your use of the Website. Where
applicable, you hereby agree to opt out of any class proceeding against DROPIFY
TECHNOLOGIES INC. otherwise commenced.
The above waiver shall not apply to claims or disputes arising under consumer protection
legislation or any other claim or dispute where a waiver of class action lawsuits is
unenforceable at law.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						15. GENERAL TERMS
					</Strong>
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						a. AMENDING THESE TERMS OF USE
					</Strong>
					<br />
					These Terms of Use may be updated and amended from time to time. We reserve the
right to change these Terms of Use at any time, and any amended Terms of Use are
effective upon posting to the Website. We will make efforts to communicate any
changes to these Terms of Use we deem material, in our sole discretion, via email or
notifications on the Website. Your continued use of the Website will be deemed to be
immediate and unconditional acceptance of any amended Terms of Use, whether or
not we deemed the amendments to be material.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						b. ASSIGNMENT
					</Strong>
					<br />
					We may assign or delegate these Terms of Use, in whole or in part, to any person or
entity at any time with or without your consent and without prior notice to you.
Users may not assign or delegate any rights or obligations under these Terms of Use,
without our prior written consent, and any unauthorized assignment and delegation is
void.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						c. NO WAIVER
					</Strong>
					<br />
					No waiver of a provision, right or remedy of this Agreement shall operate as a waiver
of any other provision, right or remedy or the same provision, right or remedy on a
future occasion.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						d. NO AGENCY
					</Strong>
					<br />
					The parties to these Terms of Use are independent contractors and are not partners or
agents. DROPIFY TECHNOLOGIES INC. has no fiduciary obligations or professional
obligations whatsoever to you arising from these Terms of Use or your use of the
Website.{"\n"}
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						e. SEVERABILITY
					</Strong>
					<br />
					In the event that any provision or part of this Agreement is found to be void or invalid
by a court of law, the remaining provisions, or parts thereof, shall be and remain in
full force and effect.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						f. ENTIRE AGREEMENT
					</Strong>
					<br />
					These Terms of Use, in conjunction with all policies and guidelines available on the
Website (including but not limited to any Privacy Policy), incorporated by reference,
constitute the entire agreement between you and DROPIFY TECHNOLOGIES INC. and
supersede all prior communications, agreements and understandings, written or oral,
with respect to the subject matter of these Terms of Use.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						16. QUESTIONS ON THESE TERMS OF USE
					</Strong>
					<br />
					We welcome you to contact us with any questions on these Terms of Use. You can send
your questions regarding these Terms of Use to the following email address:
jonathan@dropify.net
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						17. CONFIRMATION OF AGREEMENT TO TERMS OF USE
					</Strong>
					<br />
					BY PROCEEDING TO USE THE WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ,
UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF USE AND ANY
POLICIES AND NOTICES POSTED ON THE WEBSITE.
				</Text>
			</Section>
			<Section
				padding="60px 0"
				sm-padding="40px 0"
				min-height="600px"
				background="--color-light"
				display="flex"
				flex-direction="column"
				sm-min-height="auto"
			>
				<Text
					font="--base"
					text-transform="uppercase"
					letter-spacing="1px"
					max-width="850px"
					color="--grey"
					margin="0px"
				/>
				<Text
					as="h1"
					margin="8px 0px 32px"
					font="--headline1"
					md-font="--headline2"
					color="--dark"
					max-width="850px"
				>
					Privacy Policy
				</Text>
				<Text
					margin="0px"
					width="100% border-box"
					font="--lead"
					color="--grey"
					md-width="100%"
					sm-margin="0px 0px 32px 0px"
				>
					DROPIFY TECHNOLOGIES INC. PRIVACY POLICY
LAST UPDATED: 2022-03-03
					<br />
					<br />
					<Strong>
						1. OVERVIEW
					</Strong>
					<br />
					At DROPIFY TECHNOLOGIES INC. (the “Company”, “we” or “us”), we use our website and
other internet-based tools for the following purpose:
We provide services to web creators to help them monetize their business.
We are required to collect and retain information about the people and companies that
access and use the website (“Users” or “you”). This Policy applies to our privacy practices
on all communications between the Company and Users, including but not limited to
communication via:
					<br />
					1. the Company website (including the website hosted at dropify.net and any other web
domain used by the Company to communicate with users now or in the future);
					<br />
					2. any mobile, computer, or tablet-based application currently offered by Company or
developed in the future by the Company; and
					<br />
					3. all other services provided by the Company, as described on the Website (Items 1 to 3
are collectively referred to as the "Website").
This Privacy Policy (“Policy”) sets out how we use, protect, collect and preserve
information submitted by Users, including User information gathered by our technology.
This Policy should be read in conjunction with any Terms of Use or policies available on
the Website.
By accessing or submitting any information through the Website, you are agreeing to the
terms of this Policy. You are prohibited from accessing or using the Website unless you
fully understand and agree to this Policy and all Terms of Use available for review on the
Website.
If you have any questions about this Policy, please contact us by sending an email with
your questions to jonathan@dropify.net before accessing or entering any information on
the Website.
					<br />
					<br />
					<Strong>
						2. INFORMATION ABOUT USERS WE COLLECT AND STORE
					</Strong>
					<br />
					When you choose to create an account on the Website, we will collect and retain all
personal identifying information submitted to us.
We will take steps to protect the information submitted to us by Users and we will not
disclose any personal identifying information without the consent of Users, unless
otherwise set out in this Policy.
Personal identifying information may include but is not necessarily limited to:
Personal information you provided by the user. Full name, email address, phone
number, and other similar information.
We will not use personal identifying information to contact you for any reason unrelated
to your use of the Website or for commercial reasons unrelated to the business of the
Company. Except with your consent or where required by law, we will not provide
personal identifying information to any third party.
					<br />
					<br />
					<Strong>
						3. UPLOADING PERSONAL INFORMATION
					</Strong>
					<br />
					In order to facilitate their interactions on the Website, Users are able, where they deem
appropriate, to upload information including personal identifying information. Users
should only upload information to the extent that such information enhances their
experience of using the Website.
					<br />
					<br />
					<Strong>
						4. ADDITIONAL DATA COLLECTED
					</Strong>
					<br />
					To learn about your experience as a User and to make periodic improvements to the
Website and our business, we may collect and retain certain information. We will
automatically record information about your activity on the Website. This may include
your Internet Protocol (IP) address, operating system, geolocation information, locale
preferences, identification numbers associated with your computers and mobile devices,
your mobile carrier, date and time stamps associated with transactions, system
configuration information, metadata, and other electronic interactions with the Company
and the Website.
					<br />
					<Strong>
						<br />
						5. COOKIES
					</Strong>
					<br />
					To facilitate the collection of information and to enhance the experience of Users of the
Website, we use cookies which may be stored on Users’ computers and/or devices so that
we can recognize Users when they return. You may set your browser to notify you when
you receive a cookie or to not accept certain cookies. However, changing such settings may
result in you not being able to use the Website, either in its entirety or certain features.
Using cookies, you may have the option to allow us to save your user ID and login
password for future logins to the Website.
Cookies that are stored on your computer and/or device after visiting the Website may be
recognized for advertising purposes and to show you targeted advertisements after you
visit the Website.
For more information on the cookies we use or to opt-out, please contact us at
jonathan@dropify.net.
					<br />
					<br />
					<Strong>
						6. REMARKETING
					</Strong>
					<br />
					We may engage in "remarketing" activities using third-party services, which may include
but are not limited to Google Ads, Google Analytics, and Facebook. By using the Website,
you consent to allow us to provide information to third-party service parties who may
present you with advertisements about the Company after you visit the Website. We will
provide third-party providers of remarketing services with the minimum amount of
information required to facilitate remarketing, and in no circumstances will Company
provide any personal identifying information to third-parties for the purpose of
remarketing.
					<br />
					<br />
					<Strong>
						{"\n"}7. PERMITTED USES OF DATA
					</Strong>
					<br />
					We use data collected from the Website for various purposes, including to: provide the
Website to you and accompanying customer support; notify you about updates and
changes to the Website; prevent and address technical issues with the Website; monitor
usage of the Website.
Without restriction, we may use any information you submit through the Website for any
4
purpose we deem appropriate so long as it is anonymous and stripped of any and all
personal identifying information.
We may use the data collected from the Website, devices, browsers, and any other sources,
for its own purposes, both related to and unrelated to improving the Website.{"\n"}
					<br />
					<br />
					<Strong>
						8. LEGAL BASIS FOR PROCESSING PERSONAL IDENTIFYING INFORMATION
					</Strong>
					<br />
					We may process your personal identifying information for a number of reasons, including:
performing a contract with you relating to the Website;
you have provided us consent to do so;
fulfilling a legal obligation;
fulfilling our legitimate interests, which are not overridden by your rights;
for payment processing purposes.{"\n"}
					<br />
					<br />
					<Strong>
						9. ACCESSING, CORRECTING & DELETING YOUR PERSONAL INFORMATION{"\n"}
					</Strong>
					<br />
					If you wish to access or amend your personal information or to request that we
permanently delete your personal information from our servers, please send an email to
jonathan@dropify.net. We will ensure your personal information is accessible, amended
and/or deleted in a reasonable time after receiving that request. Deleting such information
may require us to terminate any account you may have created in its entirety and prevent
you from using the Website.
So long as your account remains active, we will preserve your personal information in
accordance with the Policy.
You may decline to share certain personal information with us, in which case you may not
be able to use the Website, either in its entirety or certain features.
If you have not accessed your account for a total of 3 years, we may terminate your
account, without notice, along with any personal information retained by us.
When deleting your information, whether by request or due to inactivity, we will use
standard electronic means to remove your personal information from our files.
We will also retain any and all information that we are required to retain under any
applicable laws for the full duration of time required under those laws.
					<br />
					<br />
					<Strong>
						{"\n"}10. KEEPING YOUR DATA SECURE
					</Strong>
					<br />
					We will store your information in electronic format on industry-standard servers that we
have selected. Except where required to do so by law, we will not store your personal
information in any physical or paper-based format.
The servers we select to store information engage security software and generally
accepted standards to protect personal information. The software used by these servers is
intended to prevent unauthorized access or improper use.
However, no security system is perfect and there is always a risk of unauthorized parties
accessing your personal information. Therefore, we make no guarantees or representations
as to the technical or legal compliance of the servers we use. We cannot guarantee the
complete protection and security of your personal information.
Our servers may be located outside of Canada, and thus are potentially subject to the
privacy and security laws in force in other countries. If you have concerns about your
personal information being stored electronically in foreign jurisdictions, please contact us
before proceeding to use the Website.
					<br />
					<br />
					<Strong>
						11. SECURITY BREACHES
					</Strong>
					<br />
					In the event the personal information of a User is disclosed as the result of any breach of
security of the Website or its servers, regardless of who is at fault, we will take steps to
advise all known affected Users within a reasonable timeframe of learning of the breach.
					<br />
					<br />
					<Strong>
						12. THIRD-PARTY APPLICATIONS
					</Strong>
					<br />
					To provide an enhanced experience to Users, including to track conversions and analytics,
we may engage services and features controlled by third-parties. We cannot control or
dictate how any third-party application you use in conjunction with the Website, now or at
any point in the future, will treat any personal information you transmit through their
servers when using the Website. We advise you to contact these parties directly to review
their privacy policies.
6
Without limitation, the Company uses or may use the following third-party applications
and services in conjunction with the Website:
We work with third-party service providers to help us operate, provide, improve,
understand, customize, support, and market our services. We may not have
control over third-party use of personal identifying information that may be
collected, sold, or traded.
To operate our services, we work with Cloudflare, Supabase, Autocode, Hasura,
and Coda.
To provide messaging features for our services, we work with Voiceflow, Twilio,
Twitter, WhatsApp, and Instagram.
To market, improve and understand our services, we work with Google
Analytics, Microsoft Clarity, Twitter, OpenAI, Google Ads, TikTok Ads, and
Facebook Ads.
By using any of these third-party application to interact with the Website, we will not be
given access to your login credentials, user name, password, payment information, or any
information you may provide directly to these applications.
					<br />
					<br />
					<Strong>
						13. USE OF WEBSITE BY MINORS
					</Strong>
					<br />
					The Website is not intended to be accessed by persons under the age of 18. The Company
does not engage in any marketing efforts aimed at children or minors.
Persons under the age of 18 are prohibited from using the Website in any manner or
providing any information to Company. In the event Company is provided with any
information, including personal identifying information, relating to a minor, that
information will be immediately deleted without notice.
					<br />
					<br />
					<Strong>
						14. DISCLOSURE OF PERSONAL INFORMATION
					</Strong>
					<br />
					In certain situations, we may be required to disclose personal data in response to lawful
requests by public authorities, including to meet national security or law enforcement
requirements. We may disclose your personal information to third parties:
					<br />
					1. In the event that we sell or buy any business or assets, in which case we may disclose
your personal data to the prospective seller or buyer of such business or assets.
					<br />
					2. If the Company or its assets are acquired by a third party, in which case personal data
about Users may be one of the transferred assets.
					<br />
					3. If we are under a duty to disclose or share your personal data in order to comply with
any legal obligation, or in order to enforce or apply our Terms of Use; or to protect
the rights, property, or safety of the Company, our Users, or others. This includes
exchanging information with other companies and organizations for the purposes of
fraud protection and credit risk reduction.
					<br />
					<br />
					<Strong>
						15. USER REFERRAL SERVICE
					</Strong>
					<br />
					You can choose to provide us with the names and email addresses of individuals or
organizations that you feel would be interested in learning more about our products and
services. When you choose to engage this service, we may send a preliminary email to
each address provided to us, inviting the contact to visit our website. We continue to store
the contact details in order to track the success of our referral service.
					<br />
					<br />
					<Strong>
						16. AMENDING THIS POLICY
					</Strong>
					<br />
					This Policy may be updated and amended from time to time. We reserve the right to
change this Policy at any time, and any amended Policy is effective upon posting to the
Website. We will make efforts to communicate any changes to this Policy we deem
material, in our sole discretion, via email or notifications on the Website. Your continued
use of the Website will be deemed acceptance of any amended Policy.
					<br />
					<br />
					<Strong>
						17. CONTACT US
					</Strong>
					<br />
					We encourage you to send us questions and inquiries on this Policy and the steps we take
to keep your personal information secure. Please send us an email: jonathan@dropify.net
					<br />
					We will respond to all inquiries on the Policy within 7 days.
				</Text>
			</Section>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Stack xl-justify-content="center" justify-content="center">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/terms"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem display="flex" quarkly-title="Logo" md-width="50%" width="50%">
					<Override slot="StackItemContent" align-items="center" justify-content="center" md-justify-content="flex-end" />
					<Link
						text-decoration-line="initial"
						opacity="0.6"
						font="--base"
						href="/terms"
						color="#000000"
					>
						Terms of Use & Privacy Policy
					</Link>
					{"        "}
				</StackItem>
				<StackItem
					md-width="100%"
					display="flex"
					quarkly-title="Side"
					width="25%"
					md-display="block"
					sm-display="block"
				>
					<Override
						slot="StackItemContent"
						align-items="center"
						justify-content="flex-end"
						flex-direction="row"
						md-justify-content="center"
						sm-justify-content="center"
					/>
					<Section padding="0px 0 0px 0" />
					{"   "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script async={true} place={"endOfHead"} rawKey={"61b58c1050b03822215b2eac"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MV36QFQ');"}
			</script>
			<script place={"endOfHead"} rawKey={"6246a896ffca0fbab6cc4994"}>
				{"document.addEventListener(\"DOMContentLoaded\",(e)=>{const ttclid=new URLSearchParams(window.location.search).get('ttclid');if(localStorage.getItem('ttclid')&&!ttclid){return}localStorage.setItem('ttclid',ttclid)})"}
			</script>
		</RawHtml>
	</Theme>;
});